
<template>
  <BackofficeBase :loader_prop="loader_prop">
  
    <DistributionPointMenu :point_id_prod="point_id_prod" />

    <div>
      <div>
        <div>
          {{ distribution_point_data.name }}
        </div>
        <div>
          {{ distribution_point_data.email }}
        </div>
        <div>
          {{ distribution_point_data.address}}
        </div>
            <router-link :to="{path : '/backoffice/distribution_point_edit', query : {'id' : distribution_point_data.id}}"> עריכה</router-link> 
      </div>
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";
import DistributionPointMenu from "./DistributionPointMenu"

export default {
  name: 'BackofficeDistributionPoint',
  components: {
    BackofficeBase,
    DistributionPointMenu
  },
  data() {
    return {
      loader_prop : true,
      view_data : [],
      distribution_point_data : {},
      point_id_prod : 0
    }

  },
  async mounted() {
    this.id = this.$route.query.id
    this.point_id_prod = this.$route.query.id
    let backendModel = new BackendModel()
    let view_data = await backendModel.backendRequest("/Api/service/backoffice/get_distribution_point", {"id": this.id})
    this.view_data = view_data.data.distribution_point_data
    this.loader_prop = false
    this.distribution_point_data = view_data.data.distribution_point_data
    console.log(view_data.data.distribution_point_data)
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>

